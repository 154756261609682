import defaultAvatar from 'default_avatar.svg'
import { MouseEventHandler, SyntheticEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import CollectiveBadgeSmall from 'domains/Member/CollectiveBadgeSmall'

import { SVGIcon } from 'components/Icon'

import { Maybe } from 'gql'

import { cn } from 'utils/tailwind'

import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol.svg'

import DefaultAvatar from './ActivityFacepile/DefaultAvatar'

const MemberBadgeVariants = ['yellow-green', 'black', 'none'] as const
export type MemberBadgeVariant = (typeof MemberBadgeVariants)[number]

type BadgeableAvatarUser = {
  kind?: Maybe<string>
}

export interface BadgeableAvatarProps {
  user?: BadgeableAvatarUser | { profile: BadgeableAvatarUser }
  badge?: {
    color: MemberBadgeVariant
    class: string
  }
  avatarUrl: string
  fullName?: Maybe<string>
  width?: string | number
  height?: string | number
  borderClassNames?: string
  className?: string
  alt?: Maybe<string>
  editable?: boolean
  onEditClick?: MouseEventHandler<HTMLSpanElement>
}

function getUserProfile(userOrProfile: BadgeableAvatarProps['user']) {
  if (!userOrProfile) return {}
  return 'profile' in userOrProfile ? userOrProfile.profile : userOrProfile
}

function getFontSizeFromImageSize(width?: number | string) {
  if (!width) return 'text-sm'

  const numWidth = typeof width === 'string' ? parseInt(width) : width
  if (numWidth < 40) return 'text-sm'
  if (numWidth < 80) return 'text-base'
}

const BadgeableAvatar = ({
  avatarUrl,
  user,
  width = 60,
  height = 60,
  borderClassNames,
  className,
  fullName,
  alt = 'avatar',
  editable = false,
  onEditClick
}: BadgeableAvatarProps) => {
  const useErrorAvatar = (e: SyntheticEvent<HTMLImageElement, ErrorEvent>) => {
    const imageElement = e.target as HTMLImageElement

    imageElement.src = defaultAvatar
  }
  const profile = getUserProfile(user)

  const isDefaultAvatar =
    !!(avatarUrl?.includes('default_avatar') && fullName) || !avatarUrl
  const baseAvatarClass = twMerge(
    `w-[${width}px] h-[${height}px] -ml-2 rounded-full border border-white`,
    getFontSizeFromImageSize(width),
    className
  )

  return (
    <span
      className={twMerge(
        'relative -ml-1.5 inline-block first:ml-0',
        'badgeable__avatar-wrapper ',
        className
      )}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      {isDefaultAvatar && fullName ? (
        <DefaultAvatar fullName={fullName} className={`${baseAvatarClass} ml-0`} />
      ) : (
        <img
          className={twMerge('rounded-full bg-white', borderClassNames)}
          src={avatarUrl}
          alt={alt || 'avatar'}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            minWidth: `${width}px`,
            minHeight: `${height}px`
          }}
          onError={useErrorAvatar}
          data-test="badgeable-avatar"
        />
      )}

      <div
        className={cn(
          'absolute -bottom-[4%] -left-[2%] flex h-[35%] w-[35%] items-center justify-center rounded-sm p-[3%]',
          profile.kind === 'staff' && 'bg-rb-gray-500 text-rb-white'
        )}
      >
        {profile.kind === 'collective' && (
          <CollectiveBadgeSmall className="h-full w-full" />
        )}

        {profile.kind === 'staff' && <ReforgeSymbol className="w-full fill-black" />}
      </div>
      {editable && (
        <span
          className="uk-border-circle uk-overlay uk-position-bottom-right p-0"
          style={{
            width: '25%',
            height: '25%',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
          onClick={onEditClick}
          data-test="edit-avatar"
        >
          <span
            className="uk-overlay uk-position-center cursor-pointer p-0"
            style={{ marginTop: '-1px' }}
          >
            <SVGIcon name="pencil" width={`${100 / 7}`} height={`${100 / 7}`} />
          </span>
        </span>
      )}
    </span>
  )
}

export default BadgeableAvatar
