import React, { ReactElement } from 'react'
import { Popover, PopoverAlign, PopoverPosition } from 'react-tiny-popover'
import { twMerge } from 'tailwind-merge'

import useDropdown from 'components/dropdowns/Dropdown/useDropdown'

import { onEnterKeyPress } from 'utils/keyboard'

interface DropdownProps {
  children: ReactElement
  triggerElement: ReactElement
  triggerClassName?: string
  positions?: PopoverPosition[]
  reposition?: boolean
  buttonDataTest?: string
  boundaryInset?: number
  dismissOnClick: boolean
  hoverButton?: boolean
  align?: PopoverAlign
  onToggleDropdown?: (toOpen: boolean) => void
}

const Dropdown = ({
  triggerElement,
  children,
  triggerClassName = '',
  positions = ['bottom', 'top', 'left', 'right'],
  reposition = false,
  buttonDataTest = '',
  boundaryInset,
  dismissOnClick = false,
  hoverButton = false,
  align = 'start',
  onToggleDropdown = () => {}
}: DropdownProps) => {
  const { isOpen, toggleDropdown, setIsOpen } = useDropdown()
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>

  const handleToggleDropdown = () => {
    toggleDropdown()
    onToggleDropdown?.(!isOpen)
  }
  const handleOutsideClick = () => {
    setIsOpen(false)
    onToggleDropdown?.(false)
  }
  const content = dismissOnClick
    ? () => <div onClick={handleOutsideClick}>{children}</div>
    : children

  return (
    <>
      <div ref={ref} />
      <Popover
        isOpen={isOpen}
        positions={positions}
        content={content}
        onClickOutside={handleOutsideClick}
        containerClassName="z-[101]"
        align={align}
        reposition={reposition}
        boundaryInset={boundaryInset}
        parentElement={ref.current}
      >
        {hoverButton ? (
          <div
            role="button"
            onMouseEnter={() => !isOpen && handleToggleDropdown()}
            tabIndex={0}
            data-test={buttonDataTest}
            className={twMerge(
              isOpen ? 'text-rb-teal-200' : 'text-rb-black',
              triggerClassName
            )}
          >
            {triggerElement}
          </div>
        ) : (
          <div
            role="button"
            tabIndex={0}
            onClick={handleToggleDropdown}
            onKeyUp={onEnterKeyPress(handleToggleDropdown)}
            data-test={buttonDataTest}
            className={twMerge(
              isOpen ? 'text-rb-teal-200' : 'text-rb-black',
              triggerClassName
            )}
          >
            {triggerElement}
          </div>
        )}
      </Popover>
    </>
  )
}

export default Dropdown
